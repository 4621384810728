import React, { useEffect } from 'react';
import AOS from 'aos';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-scroll';

function Main() {
    useEffect(() => {
        AOS.refresh(); // Ensure AOS is properly initialized and refresh on component mount
    }, []);
    return (
        <>
            <div className='home-main-bg'>
                <div className="container">
                    <div className="row mt-4 align-items-center" style={{ gap: '25px 0' }}>
                        <div className="col-lg-6 mt-4"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-delay="100"
                            data-aos-duration="1000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <div className="home-main-text">
                                <h1>Where Creativity Meets Technology</h1>
                                <p>
                                    Transforming businesses with creativity and exceptional results.
                                </p>
                            </div>
                            <div className="home-main-btn-div ">
                            <Link
                                    to="contact"
                                    smooth={true}
                                    duration={500}
                                >
                                    <button
                                        data-aos="flip-left"
                                        data-aos-offset="0"
                                        data-aos-delay="2000"
                                        data-aos-duration="1000"
                                        data-aos-mirror="false"
                                        data-aos-once="true"
                                        data-aos-anchor-placement="top"
                                    >
                                        Contact Us
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-0 mt-4"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-delay="100"
                            data-aos-duration="1000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <div className="home-main-gif-div">
                                <img className="home-main-gif" src={require('../assets/imgs/mainBlockPic.gif')} alt="tnf creative main" width={500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main;