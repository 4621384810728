import React from "react";
import { SiAltiumdesigner, SiAmazon, SiShopify, SiEbay } from "react-icons/si";
import { FaAddressCard, FaDigitalOcean, FaVideo, FaMobileAlt } from "react-icons/fa";
import { BsPostcard, BsFillPrinterFill } from "react-icons/bs";
import { IoNewspaperOutline, IoMdAnalytics, IoMdCodeWorking } from "react-icons/io";
import { MdSocialDistance, MdPresentation, MdContentPaste, MdOutlineWeb } from "react-icons/md";
import { AiOutlineIdcard, AiOutlineBulb } from "react-icons/ai";
import { RiPresentationFill } from "react-icons/ri";
import AOS from 'aos';

function HomeSec5() {
    return (
        <>
            <div className="sec5-bg" >
                <div className="container">
                    <div id="technologies" className="mt-4 about-text-div">
                        <h2 className="about-text-div-h2"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">Technology Tech</h2>
                    </div>
                    <div className="home-sec5-div mt-4"
                        data-aos="fade-left"
                        data-aos-offset="200"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">


                        <div className="home-sec5-lst">
                            <SiAltiumdesigner className="home-sec5-lst-svg" />
                            <p>Graphic Design</p>
                        </div>
                        <div className="home-sec5-lst">
                            <FaDigitalOcean className="home-sec5-lst-svg" />
                            <p>Digital Marketing</p>
                        </div>
                        <div className="home-sec5-lst">
                            <IoMdAnalytics className="home-sec5-lst-svg" />
                            <p>Paid Advertisements</p>
                        </div>
                        <div className="home-sec5-lst">
                            <MdSocialDistance className="home-sec5-lst-svg" />
                            <p>Social Media Marketing</p>
                        </div>
                        <div className="home-sec5-lst">
                            <RiPresentationFill className="home-sec5-lst-svg" />
                            <p>Presentation Design</p>
                        </div>
                        <div className="home-sec5-lst">
                            <BsFillPrinterFill className="home-sec5-lst-svg" />
                            <p>Printing</p>
                        </div>
                        <div className="home-sec5-lst">
                            <MdOutlineWeb className="home-sec5-lst-svg" />
                            <p>Website Design</p>
                        </div>
                        <div className="home-sec5-lst">
                            <SiAmazon className="home-sec5-lst-svg" />
                            <p>E-commerce Websites</p>
                        </div>
                        <div className="home-sec5-lst">
                            <SiShopify className="home-sec5-lst-svg" />
                            <p>Shopify Websites</p>
                        </div>
                        <div className="home-sec5-lst">
                            <FaMobileAlt className="home-sec5-lst-svg" />
                            <p>App Development</p>
                        </div>
                        <div className="home-sec5-lst">
                            <MdContentPaste className="home-sec5-lst-svg" />
                            <p>Content Creation</p>
                        </div>
                        <div className="home-sec5-lst">
                            <FaVideo className="home-sec5-lst-svg" />
                            <p>Video Editing</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSec5;