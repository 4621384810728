import React from "react";
import Main from "../HomeComponent/Main";
import AboutSec from "../HomeComponent/AboutSec";
import HomeSec3 from "../HomeComponent/HomeSec3";
import HomeSec4 from "../HomeComponent/HomeSec4";
import HomeSec5 from "../HomeComponent/HomeSec5";
import HomeSec6 from "../HomeComponent/HomeSec6";
import HomeSec7 from "../HomeComponent/HomeSec7";
import HomeSec8 from "../HomeComponent/HomeSec8";
import AOS from 'aos';

function Home() {
    return(
        <>
        <Main />
        <AboutSec />
        <HomeSec3 />
        <HomeSec4 />
        <HomeSec5 />
        <HomeSec6 />
        <HomeSec7 />
        <HomeSec8 />
        </>
    )
}

export default Home;