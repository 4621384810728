// import React, { useState } from "react";
// import { Form, Button, Col, Row } from 'react-bootstrap';
// import AOS from 'aos';
// import emailjs from "emailjs-com";
// import 'bootstrap/dist/css/bootstrap.min.css';

// function HomeSec8() {

//     // contact us form
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         category: '',
//         message: ''
//     });

//     const [errors, setErrors] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         category: '',
//         message: ''
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//         setErrors({ ...errors, [name]: '' });
//     };

//     const validateForm = () => {
//         let valid = true;
//         let newErrors = {};

//         if (!formData.firstName) {
//             valid = false;
//             newErrors.firstName = 'First name is required';
//         }
//         if (!formData.lastName) {
//             valid = false;
//             newErrors.lastName = 'Last name is required';
//         }
//         if (!formData.email) {
//             valid = false;
//             newErrors.email = 'Email is required';
//         } else {
//             const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//             if (!emailPattern.test(formData.email)) {
//                 valid = false;
//                 newErrors.email = 'Email is invalid';
//             }
//         }
//         if (!formData.choice) {
//             valid = false;
//             newErrors.category = 'Category  is required';
//         }
//         if (!formData.message) {
//             valid = false;
//             newErrors.message = 'Message is required';
//         }

//         setErrors(newErrors);
//         return valid;
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!validateForm()) {
//             return;
//         }

//         emailjs.send("service_i84l78h", "template_ninbi5f", {
//             first_name: formData.firstName,
//             last_name: formData.lastName,
//             reply_to: formData.email,
//             category: formData.category,
//             message: formData.message,
//         }, "Oxe8pD87-HoBQCIr1")
//             .then((response) => {
//                 alert("Email sent successfully!");
//                 setFormData({
//                     firstName: '',
//                     lastName: '',
//                     email: '',
//                     category: '',
//                     message: ''
//                 });
//             })
//             .catch((error) => {
//                 alert("Failed to send email. Please try again later.");
//                 console.error("Error:", error);
//             });
//     };

//     return (
//         <>
//             <div className="sec8-bg" id="contact">
//                 <div className="container">
//                     <div className="mt-4 about-text-div">
//                         <h2 className="contact-text"
//                             data-aos="zoom-in"
//                             data-aos-offset="150"
//                             data-aos-delay="0"
//                             data-aos-duration="1000"
//                             data-aos-mirror="true"
//                             data-aos-once="false"
//                             data-aos-anchor-placement="top">Contact Us</h2>
//                     </div>
//                     <div className="row justify-content-center mt-4">
//                         <div className="">
//                             <div className="row align-items-center">
//                                 <div className="col-lg-6">
//                                     <p className="contact-text-p"
//                                         data-aos="fade-right"
//                                         data-aos-offset="150"
//                                         data-aos-delay="500"
//                                         data-aos-duration="1000"
//                                         data-aos-mirror="true"
//                                         data-aos-once="false"
//                                         data-aos-anchor-placement="top">
//                                         We are trusted by over 500+ clients. Join them now and grow your business.
//                                     </p>
//                                 </div>
//                                 <div className="col-lg-6">
//                                     <div className="contact-us-form-div"
//                                         data-aos="fade-left"
//                                         data-aos-offset="200"
//                                         data-aos-delay="0"
//                                         data-aos-duration="1000"
//                                         data-aos-mirror="true"
//                                         data-aos-once="false"
//                                         data-aos-anchor-placement="top">
//                                         <Form onSubmit={handleSubmit}>
//                                             <Row className="mb-3">
//                                                 <Form.Group as={Col} xs={12} md={6} controlId="formGridFirstName">
//                                                     <Form.Label>First Name</Form.Label>
//                                                     <Form.Control
//                                                         type="text"
//                                                         placeholder="Enter your first name"
//                                                         name="firstName"
//                                                         value={formData.firstName}
//                                                         onChange={handleChange}
//                                                         isInvalid={!!errors.firstName}
//                                                     />
//                                                     <Form.Control.Feedback type="invalid">
//                                                         {errors.firstName}
//                                                     </Form.Control.Feedback>
//                                                 </Form.Group>

//                                                 <Form.Group as={Col} xs={12} md={6} controlId="formGridLastName">
//                                                     <Form.Label>Last Name</Form.Label>
//                                                     <Form.Control
//                                                         type="text"
//                                                         placeholder="Enter your last name"
//                                                         name="lastName"
//                                                         value={formData.lastName}
//                                                         onChange={handleChange}
//                                                         isInvalid={!!errors.lastName}
//                                                     />
//                                                     <Form.Control.Feedback type="invalid">
//                                                         {errors.lastName}
//                                                     </Form.Control.Feedback>
//                                                 </Form.Group>
//                                             </Row>

//                                             <Form.Group controlId="formGridEmail">
//                                                 <Form.Label>Email</Form.Label>
//                                                 <Form.Control
//                                                     type="email"
//                                                     placeholder="Enter your email"
//                                                     name="email"
//                                                     value={formData.email}
//                                                     onChange={handleChange}
//                                                     isInvalid={!!errors.email}
//                                                 />
//                                                 <Form.Control.Feedback type="invalid">
//                                                     {errors.email}
//                                                 </Form.Control.Feedback>
//                                             </Form.Group>

//                                             <Form.Group controlId="formGridChoice" className="mt-3">
//                                                 <Form.Label>Category</Form.Label>
//                                                 <Form.Control
//                                                     as="select"
//                                                     name="category"
//                                                     value={formData.category}
//                                                     onChange={handleChange}
//                                                     isInvalid={!!errors.category}
//                                                 >
//                                                     <option value="">Please choose an option</option>
//                                                     <option value="Graphic Design">Graphic Design</option>
//                                                     <option value="Digital Marketing">Digital Marketing</option>
//                                                     <option value="Paid Advertisements">Paid Advertisements</option>
//                                                     <option value="Social Media Marketing">Social Media Marketing</option>
//                                                     <option value="Presentation Design">Presentation Design</option>
//                                                     <option value="Printing">Printing</option>
//                                                     <option value="Website Design">Website Design</option>
//                                                     <option value="E-commerce Websites">E-commerce Websites</option>
//                                                     <option value="Shopify Websites">Shopify Websites</option>
//                                                     <option value="App Development">App Development</option>
//                                                     <option value="Content Creation">Content Creation</option>
//                                                     <option value="Video Editing">Video Editing</option>
//                                                 </Form.Control>
//                                                 <Form.Control.Feedback type="invalid">
//                                                     {errors.category}
//                                                 </Form.Control.Feedback>
//                                             </Form.Group>

//                                             <Form.Group controlId="formGridMessage" className="mt-3">
//                                                 <Form.Label>Message</Form.Label>
//                                                 <Form.Control
//                                                     as="textarea"
//                                                     rows={5}
//                                                     placeholder="Enter your message"
//                                                     name="message"
//                                                     value={formData.message}
//                                                     onChange={handleChange}
//                                                     isInvalid={!!errors.message}
//                                                 />
//                                                 <Form.Control.Feedback type="invalid">
//                                                     {errors.message}
//                                                 </Form.Control.Feedback>
//                                             </Form.Group>

//                                             <Button type="submit" className="mt-3 contact-main-btn-div">
//                                                 Submit
//                                             </Button>
//                                         </Form>
//                                     </div>

//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default HomeSec8;

























import React, { useState } from "react";
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import AOS from 'aos';
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';

function HomeSec8() {

    // contact us form
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        category: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        category: '',
        message: ''
    });

    const [showModal, setShowModal] = useState(false); // state for modal visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!formData.firstName) {
            valid = false;
            newErrors.firstName = 'First name is required';
        }
        if (!formData.lastName) {
            valid = false;
            newErrors.lastName = 'Last name is required';
        }
        if (!formData.email) {
            valid = false;
            newErrors.email = 'Email is required';
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                valid = false;
                newErrors.email = 'Email is invalid';
            }
        }
        if (!formData.category) {
            valid = false;
            newErrors.category = 'Category is required';
        }
        if (!formData.message) {
            valid = false;
            newErrors.message = 'Message is required';
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        emailjs.send("service_i84l78h", "template_ninbi5f", {
            first_name: formData.firstName,
            last_name: formData.lastName,
            reply_to: formData.email,
            category: formData.category,
            message: formData.message,
        }, "Oxe8pD87-HoBQCIr1")
            .then((response) => {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    category: '',
                    message: ''
                });
                setShowModal(true); // Show the modal after successful email
            })
            .catch((error) => {
                alert("Failed to send email. Please try again later.");
                console.error("Error:", error);
            });
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className="sec8-bg" id="contact">
                <div className="container">
                    <div className="mt-4 about-text-div">
                        <h2 className="contact-text"
                            data-aos="zoom-in"
                            data-aos-offset="150"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">Contact Us</h2>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <p className="contact-text-p"
                                        data-aos="fade-right"
                                        data-aos-offset="150"
                                        data-aos-delay="500"
                                        data-aos-duration="1000"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top">
                                        We are trusted by over 500+ clients. Join them now and grow your business.
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-us-form-div"
                                        data-aos="fade-left"
                                        data-aos-offset="200"
                                        data-aos-delay="0"
                                        data-aos-duration="1000"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} xs={12} md={6} controlId="formGridFirstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your first name"
                                                        name="firstName"
                                                        value={formData.firstName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.firstName}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} xs={12} md={6} controlId="formGridLastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your last name"
                                                        name="lastName"
                                                        value={formData.lastName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.lastName}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Form.Group controlId="formGridEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formGridCategory" className="mt-3">
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="category"
                                                    value={formData.category}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.category}
                                                >
                                                    <option value="">Please choose an option</option>
                                                    <option value="Graphic Design">Graphic Design</option>
                                                    <option value="Digital Marketing">Digital Marketing</option>
                                                    <option value="Paid Advertisements">Paid Advertisements</option>
                                                    <option value="Social Media Marketing">Social Media Marketing</option>
                                                    <option value="Presentation Design">Presentation Design</option>
                                                    <option value="Printing">Printing</option>
                                                    <option value="Website Design">Website Design</option>
                                                    <option value="E-commerce Websites">E-commerce Websites</option>
                                                    <option value="Shopify Websites">Shopify Websites</option>
                                                    <option value="App Development">App Development</option>
                                                    <option value="Content Creation">Content Creation</option>
                                                    <option value="Video Editing">Video Editing</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.category}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="formGridMessage" className="mt-3">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder="Enter your message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.message}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Button type="submit" className="mt-3 contact-main-btn-div">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your email has been successfully sent.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseModal}
                        style={{ backgroundColor: '#304cd1', borderRadius: '50px' }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default HomeSec8;
