import React from "react";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link as ScrollLink } from "react-scroll";
import AOS from 'aos';
import { FaFacebook, FaInstagram } from "react-icons/fa";

function FooterComponent() {
    return (
        <>
            <div className="footer-bg">
                <div className="container">
                    <div className="row" style={{ gap: '35px 0' }}>
                        <div className="col-md-4">
                            <div className="footer-logo-div">
                                <img src={require('../assets/logo/logo.png')} alt="tnf logo" width={150} />
                            </div>
                            <div className='copyRight'>
                                copyright © 2024 TNF Creatives
                            </div>
                            <div className="footer-social-icons">
                                <a href="https://www.facebook.com/profile.php?id=100084336861898" target="_blank" rel="noopener noreferrer">
                                    <FaFacebook size={20} />
                                </a>
                                <a href="https://www.instagram.com/tnf.creatives/?hl=en" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '15px' }}>
                                    <FaInstagram size={20} />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="footer-text-div">
                                <h4>Learn More</h4>
                                <ul className="footer-list">
                                    <li>
                                        <ScrollLink to="about" smooth={true} duration={500} offset={-80}>About</ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="skills" smooth={true} duration={500} offset={-80}>Skills</ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="technologies" smooth={true} duration={500} offset={-80}>Technologies</ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink to="contact" smooth={true} duration={500} offset={-80}>Contact</ScrollLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="footer-text-div">
                                <h4>Get in Touch</h4>
                                <ul>
                                    <li>
                                        <div className="footer-c-text">
                                            <MdEmail />
                                            <p>tnfcreatives@gmail.com</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="footer-c-text">
                                            <FaLocationDot />
                                            <p>USA/Canada</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterComponent;
