import React from "react";
import Card from 'react-bootstrap/Card';
import AOS from 'aos';

function HomeSec3() {
    let skills = [
        { skillName: 'Graphic design', discription: "Stand out with our creative graphic design services, including logos, brochures, banners, and more, tailored to convey your brand’s message effectively." },
        { skillName: 'Digital marketing', discription: "Maximize your online presence with our data-driven digital marketing services, from SEO and SEM to PPC and email campaigns, designed to increase your ROI." },
        { skillName: 'Social media marketing', discription: "Grow and engage your audience through targeted social media campaigns on platforms like Facebook, Instagram, Twitter, and LinkedIn, with compelling content and visuals." },
        { skillName: 'Ads run', discription: "Effective ad campaigns on Google, Facebook, Instagram, and more to drive traffic and conversions." },
        { skillName: 'Content creation', discription: "Attract and retain your audience with high-quality content such as blog posts, articles, infographics, and eBooks, customized to your brand’s voice and audience." },
        { skillName: 'Presentation', discription: "Impress your audience with professionally designed presentations that clearly and effectively communicate your message for meetings, pitches, webinars, and conferences." },
        { skillName: 'Website design', discription: "Create a strong online presence with our responsive, user-friendly, and visually appealing website designs that reflect your brand identity and offer an excellent user experience." },
        { skillName: 'Video editing', discription: "Enhance your video content with our professional editing services, including cutting, trimming, effects, and soundtracks, for promotional videos, tutorials, vlogs, and more." },
    ];

    return (
        <>
            <div className="container" id="skills">
                <div className="mt-4 about-text-div"
                    data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top">
                    <h2>What we Offer</h2>
                </div>
                <div className="row justify-content-center mt-4"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top">
                    {skills.map((item, index) => (
                        <Card style={{ width: '18rem', margin: '10px' }} className="sec3-card-main">
                            <Card.Body>
                                <Card.Subtitle className="mb-2 text-muted sec3-card-main-h">
                                    <h5>{item.skillName}</h5>
                                </Card.Subtitle>
                                <Card.Text>
                                    {item.discription}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )
}

export default HomeSec3;