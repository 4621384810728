import React from "react";
import Card from 'react-bootstrap/Card';
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import AOS from 'aos';

function HomeSec7() {
    let feedback = [
        { clientName: 'Ben Anderson', discription: "I needed a logo for my business, but had absolutely no idea what I wanted. I messaged TNF Creatives and told them the name of my business. That was about all they had to go on. Within a couple of days, they had created an amazing and professional looking logo. I would highly recommend and encourage anyone who needs a graphic designer to get in touch with TNF Creatives. You won’t be disappointed." },
        { clientName: 'Will Holt', discription: "Great work at a fair price. I just gave some guardrails and they took off with the creativity for my small business logo. Thanks!" },
        { clientName: 'David Jackson', discription: "Leslie has been a superstar at taking my idea and putting it into a useable trademark logo for my Band! It’s one of those steps so many people don’t do right and TNFCreatives has knocked it out of the Park! Wonderful work!" },
        { clientName: 'Liz Wyatt Covey', discription: "I have been in business for 15 years without a logo and have had very boring business cards. Emma Daniel from TNFCreatives designed a great logo and card for me in order to prepare for a website. She was great to work with, and did this for me in less than a week for a very reasonable price. I can't wait to see what is in store for my new website! Thank you Emma!!" },
        { clientName: 'Ashley Nicole Benton', discription: "Very easy communication and get what you want in a timely manner! They were excellent at doing exactly what I asked for without any issues! I would definitely use TNF creatives again!" },
        { clientName: 'Patrick C. Sullivan', discription: "TNFCreatives is very easy to work with and flexible with their designs. I would recommend them for logos for anything!" }
    ];

    return (
        <>
            <div className="container">
                <div className="mt-4 about-text-div">
                    <h2 data-aos="zoom-in"
                        data-aos-offset="200"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">Feedback</h2>
                </div>
                <div className="row justify-content-center mt-4"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top">
                    {feedback.map((item, index) => (
                        <Card style={{ width: '18rem', margin: '10px', height: 'auto' }} className="sec7-card-main">
                            <Card.Body>
                                <Card.Subtitle className="mb-2 text-muted sec7-card-main-h">
                                    <h5 className="sec7-card-main-h5">{item.clientName}</h5>
                                </Card.Subtitle>
                                <Card.Text className="text-muted sec7-card-main-b">
                                    <p><FaQuoteLeft className="sec7-card-main-b-svg" />{item.discription}<FaQuoteRight className="sec7-card-main-b-svg" /></p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )
}

export default HomeSec7;