import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HiLightBulb } from 'react-icons/hi';
import { FaHandsHelping, FaFlask, FaChartLine } from 'react-icons/fa';
import "./HomeSec4.css"

function HomeSec4() {
    useEffect(() => {
        AOS.init({ duration: 1000, once: false });
    }, []);

    return (
        <div className="container">
            <div className="mt-4 about-text-div"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="1000"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top">
                <h2>Why Choose Us</h2>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top">
                    <div className="home-sec4-img-div">
                        <img src={require('../assets/imgs/Aboutpic.gif')} alt="why choose tnf creative" />
                    </div>
                </div>
                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top">
                    <div className="home-sec4-txt-div">
                        <ul className="creative-list">
                            <li className="list-item" data-aos="fade-up" data-aos-delay="100">
                                <HiLightBulb className="list-icon" />
                                <div className="list-content">
                                    <b>Creativity: </b>
                                    <span>We believe in thinking outside the box and pushing boundaries to create something truly unique.</span>
                                </div>
                            </li>
                            <li className="list-item" data-aos="fade-up" data-aos-delay="200">
                                <FaHandsHelping className="list-icon" />
                                <div className="list-content">
                                    <b>Collaboration: </b>
                                    <span>We work closely with our clients to understand their goals and vision.</span>
                                </div>
                            </li>
                            <li className="list-item" data-aos="fade-up" data-aos-delay="300">
                                <FaFlask className="list-icon" />
                                <div className="list-content">
                                    <b>Innovation: </b>
                                    <span>We stay up-to-date with the latest design and marketing trends to ensure our clients receive cutting-edge solutions.</span>
                                </div>
                            </li>
                            <li className="list-item" data-aos="fade-up" data-aos-delay="400">
                                <FaChartLine className="list-icon" />
                                <div className="list-content">
                                    <b>Results-driven: </b>
                                    <span>We focus on delivering measurable results that drive growth and success.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSec4;
