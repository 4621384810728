import React from "react";
import AOS from 'aos';

function HomeSec6() {
    return (
        <>
            <div className="container">
                <div className="mt-4 about-text-div">
                    <h2 data-aos="zoom-in"
                        data-aos-offset="200"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">Our Story</h2>
                </div>
                <div className="row align-items-center mt-4">
                    <div className="col-lg-6">
                        <div className="home-sec6-img-div">
                            <img src={require('../assets/imgs/whyChooseUs.gif')} alt="tnf creative story" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home-sec6-txt-div">
                            <p>
                                We met while working on a project together and realized our skills complemented each other perfectly. We decided to join forces and create a company that offers a 360-degree approach to branding and marketing. Our mission is to help businesses like yours stand out in a crowded digital landscape as well as support small businesses with budgetable rates and packages.
                            </p>
                            <p>
                                If you're ready to take your brand to the next level, let's collaborate! Contact us to learn more about our services and how we can help you achieve your goals.
                            </p>
                            <p>
                                Remember to customize this to fit your personal style and branding!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSec6;