import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"; //bootstrap link its important
import 'aos/dist/aos.css'; // You can also use <link> for styles
import AOS from 'aos';
import Header from './component/Screen/Header';
import Home from "./component/Screen/Home";
import Footer from "./component/Screen/Footer";


function App() {
  return (
    <div className="App">
       <Header />
       <Home /> 
       <Footer />
    </div>
  );
}

export default App;
