import React from "react";
import Topbar from "../HeaderAndFooter/Topbar";

function Header() {
    return (
      <>
        <Topbar />
      </>
    );
  }
  
  export default Header;