import React from "react";
import FooterComponent from "../HeaderAndFooter/FooterComponent";

function Footer () {
    return(
        <>
        <FooterComponent />
        </>
    )
}

export default Footer;