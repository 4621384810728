import React, { useState } from 'react';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import AOS from 'aos';

function Topbar() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);
    const handleLinkClick = (to, event) => {
        event.preventDefault();
        setShowOffcanvas(false);
        scroll.scrollTo(to, {
            smooth: true,
            duration: 500,
            offset: -80 // Adjust this value to your needs to account for fixed header height
        });
    };

    return (
        <>
            <div className='header-main-bg'>
                <Navbar expand="lg" className="fixed-top mb-3">
                    <Container>
                        <Navbar.Brand href="#">
                            <img src={require('../assets/logo/logo.png')} alt='tnf creactive logo' width={100} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleOffcanvasShow} />
                        <Navbar.Offcanvas
                            show={showOffcanvas}
                            onHide={handleOffcanvasClose}
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="offcanvasNavbarLabel">
                                    <img src={require('../assets/logo/logo.png')} alt='tnf creactive logo' width={110} />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Item>
                                        <ScrollLink
                                            to="about"
                                            smooth={true}
                                            duration={500}
                                            offset={-80}
                                            className="nav-link header-btns"
                                            onClick={handleOffcanvasClose}
                                        >
                                            About
                                        </ScrollLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <ScrollLink
                                            to="skills"
                                            smooth={true}
                                            duration={500}
                                            offset={-80}
                                            className="nav-link header-btns"
                                            onClick={handleOffcanvasClose}
                                        >
                                            Skills
                                        </ScrollLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <ScrollLink
                                            to="technologies"
                                            smooth={true}
                                            duration={500}
                                            offset={-80}
                                            className="nav-link header-btns"
                                            onClick={handleOffcanvasClose}
                                        >
                                            Technologies
                                        </ScrollLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <ScrollLink
                                            to="contact"
                                            smooth={true}
                                            duration={500}
                                            offset={-80}
                                            className="nav-link header-btns"
                                            onClick={handleOffcanvasClose}
                                        >
                                            Contact
                                        </ScrollLink>
                                    </Nav.Item>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
            <div style={{ paddingTop: '80px' }}>
                {/* Add padding to the top to prevent overlap with fixed header */}
            </div>
        </>
    );
}

export default Topbar;
