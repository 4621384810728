import React from "react";
import AOS from 'aos';

function AboutSec() {
    return (
        <>
            {/* <div className="about-sec-bg"> */}
            <div className="container" id="about">
                <div className="mt-4 about-text-div">
                    <h2 className="about-text"
                        data-aos="zoom-in"
                        data-aos-offset="200"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">About</h2>
                </div>
                <div className="row mt-4" style={{ gap: '25px 0' }}>
                    <div className="col-lg-6 mt-4"
                        data-aos="fade-up"
                        data-aos-offset="200"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">
                        <div className="about-main-git-div">
                            <img src={require('../assets/imgs/About.gif')} alt="tnf creative about" />
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4"
                        data-aos="fade-up"
                        data-aos-offset="200"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-anchor-placement="top">
                        <div className="about-body-text">
                            <p className="about-text">We're a creative duo passionate about helping businesses thrive in the digital world. With expertise in graphic design and digital marketing, we offer a unique combination of skills to elevate your brand's visual identity and online presence.</p>
                            <p className="about-text">Our team have a keen eye for detail and a love for creativity, We craft visually stunning designs that capture your brand's essence. Moreover  With a knack for strategy and a passion for innovation, we develop effective marketing campaigns that drive results and grow your online presence.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default AboutSec;